var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('div',{staticClass:"Greeting"},[_c('h2',{staticClass:"fw-large blue-dark mb-3 fs-10"},[_vm._v(" Hello, "+_vm._s(_vm._f("getUserFullName")(_vm.getAuthenticatedUser))+" ")])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-text":"Please wait, we are getting your dashboard ready.","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[(_vm.hasCustomDashboard && _vm.activeCustomDashboard)?_c('div',{staticClass:"dashboard-tabs"},[_c('el-tabs',{attrs:{"type":"card"},on:{"tab-click":_vm.changeDashboard},model:{value:(_vm.activeCustomDashboardId),callback:function ($$v) {_vm.activeCustomDashboardId=$$v},expression:"activeCustomDashboardId"}},_vm._l((_vm.customDashboardsList),function(item,index){return _c('el-tab-pane',{key:index + item._id,attrs:{"label":item.name,"name":item._id}})}),1),(
        (_vm.activeCustomDashboard.allCustomFilters &&
          _vm.activeCustomDashboard.allCustomFilters.length) ||
        (_vm.activeCustomDashboard.includeGlobalDateFilter &&
          _vm.activeCustomDashboard.allStatComponents &&
          _vm.activeCustomDashboard.allStatComponents.length)
      )?_c('el-row',{staticClass:"dashboard-filters"},[_c('el-col',{staticStyle:{"border-bottom":"#d2dcfa solid 1px"},attrs:{"span":24}},[_c('CustomDashboardFilters',{attrs:{"allDashboardstats":_vm.activeCustomDashboard}})],1)],1):_vm._e(),(
        _vm.activeCustomDashboard &&
        _vm.activeCustomDashboard.allStatComponents &&
        _vm.activeCustomDashboard.allStatComponents.length
      )?_c('el-row',[_c('el-col',{attrs:{"span":24}},[_c('CustomDashboardStats',{staticClass:"Stats-Style",attrs:{"allDashboardstats":_vm.activeCustomDashboard,"hide_options":true,"refresh":_vm.refresh}})],1)],1):_vm._e()],1):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.statsLoading),expression:"statsLoading"}],staticClass:"stats-container",attrs:{"element-loading-text":"Loading...","element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.8)"}},[_c('el-scrollbar',[(_vm.activeDashboardStatics)?_c('div',{staticClass:"mb-1"},[_c('el-row',_vm._l((_vm.activeDashboardStatics),function(stat,index){return _c('el-col',{key:index,staticClass:"card dashboard-top-buttons",staticStyle:{"min-with":"100px"},style:(_vm.isSelectedStats(stat.slug)
                ? ("color:white;background-color:" + (stat.active_color))
                : ("background-color:" + (stat.background_color))),attrs:{"lg":3,"md":6,"sm":12,"xs":12}},[_c('div',{staticClass:"p-1",staticStyle:{"width":"150px"},on:{"click":function($event){return _vm.getSelecetedDocumentStatus(stat)}}},[_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('p',{staticClass:"Title_sty mb-0 fw-300 pl-1"},[_vm._v(_vm._s(stat.title))])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"imgstyle",attrs:{"src":require(("@/assets/img/icons/" + (_vm.isSelectedStats(stat.slug)
                        ? 'dashboardActiveIcons'
                        : 'dashboardStatsIcons') + "/" + (stat.key) + ".svg")),"alt":"Docs"}})]),_c('div',{staticClass:"number_sty count fs-6 fw-light",style:(_vm.isSelectedStats(stat.slug) ? "color:white" : "color:black")},[_vm._v(" "+_vm._s(stat.count)+" ")])])])}),1)],1):_vm._e()])],1),(
      _vm.customDashboardsList &&
      _vm.customDashboardsList.length &&
      _vm.activeCustomDashboard &&
      _vm.activeCustomDashboard.allStatComponents &&
      _vm.activeCustomDashboard.allStatComponents.length
    )?_c('hr',{staticClass:"hr"}):_vm._e(),(_vm.hasCustomDashboard && _vm.activeCustomDashboard)?_c('el-row',[(
        _vm.activeCustomDashboard.allTableComponents &&
        _vm.activeCustomDashboard.allTableComponents.length
      )?_c('div',[_c('div',{staticClass:"wrapper-grid"},[_c('draggable',{staticClass:"drag-grid",attrs:{"disabled":!_vm.activeCustomDashboard.allowUserChangeOrder},on:{"end":_vm.switchPositions}},_vm._l((_vm.activeCustomDashboard.allTableComponents),function(component,index){return _c('div',{key:component.entity_id +
              '_' +
              component.keyValue +
              '_table_' +
              index,staticClass:"grid-item",style:(_vm.expendedIndexs.indexOf(index) != -1 || _vm.getIsMobile
                ? 'grid-column: 1/3;'
                : '')},[(component.type == 'TABLE')?_c('CustomDashboardTable',{attrs:{"component":component,"entity_id":component.entity_id,"filter_id":component.filter_id,"index":index,"globalVariablesData":_vm.globalVariablesData,"hide_options":true},on:{"expendTable":_vm.expendTable}}):(component.type == 'CHART')?_c('CustomDashboardPieChart',{attrs:{"component":component,"index":index,"hide_options":true},on:{"expendTable":_vm.expendTable}}):(component.type == 'CALENDAR')?_c('CustomDashboardCalendar',{attrs:{"component":component,"index":index,"hide_options":true},on:{"expendTable":_vm.expendTable}}):(component.type == 'LOGINS')?_c('CustomDashboardLogins',{attrs:{"component":component,"index":index,"hide_options":true},on:{"expendTable":_vm.expendTable}}):(component.type == 'ENTITY_VIEW')?_c('EntityViews',{attrs:{"entityViewId":component.entity_view_id,"fromCustomDashboard":true,"index":index,"component":component,"hide_options":true},on:{"expendTable":_vm.expendTable}}):_vm._e()],1)}),0)],1)]):_c('div',[_c('el-empty',{attrs:{"description":"No tables configured"}})],1)]):_c('el-row',[_c('el-col',{attrs:{"xl":12,"lg":10,"md":8,"sm":12,"xs":12}},[_c('h3',{staticClass:"fw-medium blue-dark mb-3 fs-6"},[_vm._v(" "+_vm._s(("" + _vm.getSelecetedDocStatus))+" ")])]),(
        !_vm.getIsMobile &&
        this.$route &&
        this.$route.query &&
        this.$route.query.type &&
        this.$route.query.type == 'company-documents' &&
        _vm.checkPermissionByPermissionName('viewDocument')
      )?_c('el-col',{staticStyle:{"margin-left":"100px"},attrs:{"xl":8,"lg":8,"md":8,"sm":12,"xs":12}},[_c('el-select',{staticClass:"select float-right mr-3 select-company_type",attrs:{"filterable":"","clearable":"","placeholder":"Select Company User"},model:{value:(_vm.selected_company_user),callback:function ($$v) {_vm.selected_company_user=$$v},expression:"selected_company_user"}},_vm._l((_vm.companyUsers),function(status,index){return _c('el-option',{key:index,attrs:{"value":status.first_name + ' ' + status.last_name,"label":status.first_name + ' ' + status.last_name}})}),1)],1):_vm._e(),(
        !_vm.getIsMobile &&
        this.$route &&
        this.$route.query &&
        this.$route.query.type &&
        this.$route.query.type == 'company-documents'
      )?_c('el-col',{attrs:{"xl":4,"lg":4,"md":8,"sm":12,"xs":12}},[_c('el-input',{staticClass:"search float-right mb-3 icon-search",attrs:{"placeholder":"Search","clearable":""},model:{value:(_vm.search_string),callback:function ($$v) {_vm.search_string=$$v},expression:"search_string"}},[_c('i',{staticClass:"el-icon-search"})])],1):_c('el-col',{style:(_vm.getIsMobile ? '' : 'margin-left:500px'),attrs:{"xl":6,"lg":4,"md":12,"sm":12,"xs":12}},[_c('el-tooltip',{attrs:{"placement":"top","hide-after":2000}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v("You can search by first name, last name,email, and"),_c('br'),_vm._v("address that are present in the document users.")]),_c('el-input',{staticClass:"search float-right mb-3 icon-search",attrs:{"placeholder":"Search","clearable":""},model:{value:(_vm.search_string),callback:function ($$v) {_vm.search_string=$$v},expression:"search_string"}},[_c('i',{staticClass:"el-icon-search"})])],1)],1),_c('el-col',{attrs:{"xl":24,"lg":24,"md":24,"sm":24,"xs":24}},[_c('DocumentsList',{attrs:{"page_size":"5","selectedDocStatus":_vm.selecetedDocStatus,"selected_company_user":_vm.selected_company_user,"search_string":_vm.search_string},on:{"reloadDashboardStats":_vm.getDashboardStats}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }